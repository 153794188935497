<template>
  <div>
    <div class="editor-wrapper">
      <div class="editor-toolbar">
        <v-spacer />
        <v-checkbox
          v-model="yamlLineWrap"
          label="Line wrap"
          color="white"
          dense
        />
        <hr>
        <v-checkbox
          v-model="yamlLineNumbers"
          label="Line numbers"
          color="white"
          dense
        />
      </div>
      <div class="yaml-editor editor">
        <textarea aria-label="input-field" ref="yaml" />
      </div>
    </div>
    <div class="editor-wrapper">
      <div class="editor-toolbar">
        <v-btn-toggle
          v-model="view"
          mandatory
          tile
          borderless
        >
          <v-btn x-small outlined elevation="0">
            <v-icon small>mdi-file-tree</v-icon>
          </v-btn>
          <v-btn x-small outlined elevation="0">
            <v-icon small>mdi-code-json</v-icon>
          </v-btn>
        </v-btn-toggle>
        <v-spacer />
        <v-switch
          color="white"
          style="margin-top: 14px"
          v-model="readOnly"
          label="Read only"
          dense
        />
        <div class="json-options hidden">
          <hr>
          <v-checkbox
            v-model="jsonLineWrap"
            label="Line wrap"
            color="white"
            dense
          />
          <hr>
          <v-checkbox
            v-model="jsonLineNumbers"
            label="Line numbers"
            color="white"
            dense
          />
        </div>
      </div>
      <div ref="json" class="editor hidden read-only">
        <textarea aria-label="input-field" ref="js" />
      </div>
      <JsonEditorVue
        class="jsoneditor-container read-only"
        ref="obj"
        mode="tree"
        v-model="object"
        :readOnly="readOnly"
        :parser="jsonParser"
        :onRenderMenu="onRenderMenu" />
    </div>
  </div>
</template>

<script>
import { parse as yamlParse, stringify as yamlStringify } from 'yaml';
import CodeMirror from 'codemirror';
import JsonEditorVue from 'json-editor-vue';
import { parse, stringify, isEmpty } from '../utility';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/yaml/yaml';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/yaml-lint';

window.jsyaml = require('js-yaml'); // Introduce js-yaml to improve core support for grammar checking for codemirror

// eslint-disable-next-line no-undef
export default {
  name: 'YamlEditor',
  components: { JsonEditorVue },
  data() {
    return {
      yamlEditor: null,
      jsonEditor: null,
      jsonEditorElement: null,
      objectEditorElement: null,
      readOnly: true,
      object: null,
      yamlLineWrap: false,
      yamlLineNumbers: true,
      jsonLineWrap: false,
      jsonLineNumbers: true,
      view: 0,
      jsonParser: { stringify, parse },
      editorOptions: {
        lineNumbers: true,
        gutters: ['CodeMirror-lint-markers'],
        lint: true,
        mode: 'text/x-yaml',
        extraKeys: {
          Tab(cm) {
            const spaces = Array(cm.getOption('indentUnit') + 1).join(' ');
            cm.replaceSelection(spaces);
          },
        },
      },
      value: '',
    };
  },
  watch: {
    value(value) {
      const editorValue = this.yamlEditor.getValue();
      if (value !== editorValue) {
        this.yamlEditor.setValue(this.value);
      }
    },
    object(object) {
      if (!this.compareObjectToObject(yamlParse(
        this.getYamlValue(),
        { intAsBigInt: true },
      ), object)) {
        this.value = isEmpty(object) ? '' : yamlStringify(object);
      }
    },
    readOnly: {
      handler() {
        this.jsonEditor.setOption('readOnly', this.readOnly);
        this.jsonEditorElement.classList.toggle('read-only');
        this.objectEditorElement.classList.toggle('read-only');
      },
    },
    yamlLineWrap: {
      handler() {
        this.yamlEditor.setOption('lineWrapping', this.yamlLineWrap);
      },
    },
    yamlLineNumbers: {
      handler() {
        this.yamlEditor.setOption('lineNumbers', this.yamlLineNumbers);
      },
    },
    jsonLineWrap: {
      handler() {
        this.jsonEditor.setOption('lineWrapping', this.jsonLineWrap);
      },
    },
    jsonLineNumbers: {
      handler() {
        this.jsonEditor.setOption('lineNumbers', this.jsonLineNumbers);
      },
    },
    view: {
      handler() {
        this.switchMode();
      },
    },
  },
  mounted() {
    this.yamlEditor = CodeMirror.fromTextArea(this.$refs.yaml, this.editorOptions);
    this.jsonEditor = CodeMirror.fromTextArea(this.$refs.js, {
      ...this.editorOptions,
      readOnly: this.readOnly,
    });

    this.jsonEditorElement = this.$refs.json;
    this.objectEditorElement = this.$refs.obj.$el;

    this.yamlEditor.setValue(this.value);
    this.object = {};
    this.jsonEditor.setValue(stringify(this.object));

    this.yamlEditor.on('change', this.onYamlChange);
    this.jsonEditor.on('change', this.onJsonChange);
  },
  methods: {
    getYamlValue() {
      return this.yamlEditor.getValue();
    },
    getJsonValue() {
      return this.jsonEditor.getValue();
    },
    onRenderMenu(menu) {
      const newMenu = menu.slice(4);
      return newMenu;
    },
    compareJsonToJson(json1, json2) {
      return stringify(parse(json1))
      === stringify(parse(json2));
    },
    compareJsonToObject(json, object) {
      return stringify(parse(json)) === stringify(object);
    },
    compareObjectToObject(object1, object2) {
      return stringify(object1) === stringify(object2);
    },
    onYamlChange() {
      try {
        const newValue = this.getYamlValue();
        let obj = yamlParse(newValue, { intAsBigInt: true }) || {};
        obj = parse(stringify(obj));
        if (!this.compareJsonToObject(this.getJsonValue(), obj)) {
          const json = stringify(obj, null, 2);
          this.object = obj;
          this.jsonEditor.setValue(json);
        }
      } catch (err) {
        // continue regardless of error
      }
    },
    onJsonChange() {
      try {
        const newValue = this.getJsonValue();
        if (newValue === '') this.jsonEditor.setValue(stringify({}));
        const newObject = (newValue === '') ? {} : parse(newValue);
        const yaml = isEmpty(newObject) ? '' : yamlStringify(newObject);
        if (!this.compareObjectToObject(newObject, yamlParse(
          this.getYamlValue(),
          { intAsBigInt: true },
        ))) {
          this.yamlEditor.setValue(yaml);
          this.object = newObject;
        }
      } catch (err) {
        // continue regardless of error
      }
    },
    switchMode() {
      this.objectEditorElement.classList.toggle('hidden');
      this.jsonEditorElement.classList.toggle('hidden');
      document.querySelector('.json-options').classList.toggle('hidden');
      this.jsonEditor.refresh();
    },
  },
};
</script>

<style scoped>
.editor-wrapper {
  padding: 8px;
  position: relative;
  flex-basis: 100%;
  max-width: 100%;
  height: 50%;
}
.editor-toolbar {
  width: 100%;
  height: 35px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  margin: 0;
  box-sizing: border-box;
  color: #fff;
  font-size: 12px;
  background-color: #3883fa;
}
.editor-toolbar > * {
  margin-right: 2px;
}
.editor-toolbar >>> .v-label,
.editor-toolbar >>> i {
  color: white;
}
.editor-toolbar >>> .v-label {
  margin-left: -2px;
  font-size: 12px;
  white-space: nowrap;
}
.v-input--checkbox {
  height: 100%;
  margin: 0;
  margin-left: -2px;
}
.v-input--checkbox >>> .v-label {
  margin-left: -6px;
}
.editor-toolbar hr {
  border-width: 0;
  border-left: 1px solid #fff9;
  height: 66%;
  width: 0;
  margin: 0 5px;
  color: transparent;
}
.json-options {
  display: contents;
}
.editor {
  height: calc(100% - 35px);
  border: 1px solid #3883fa;
}
.hidden {
  display: none;
}
.editor >>> .CodeMirror {
  height: 100%;
  font-size: 14px;
}
.jsoneditor-container {
  height: calc(100% - 35px);
  --jse-main-border: transparent;
  border: 1px solid #3883fa;
  overflow: hidden;
}
.editor.read-only >>> .CodeMirror,
.jsoneditor-container.read-only >>> .jse-contents {
  mix-blend-mode: luminosity;
  background-color: #f0f0f0;
}
@media (orientation: landscape) {
  .editor-wrapper {
    flex-basis: 50%;
    max-width: 50%;
    height: 100%;
  }
}
</style>
