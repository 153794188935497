<template>
  <div id="app">
    <v-app>
      <v-main class="page">
        <v-container
          fluid
          fill-height>
          <yaml-editor class="editor-container" />
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import YamlEditor from './components/YamlEditor';

export default {
  name: 'YamlEditorDemo',
  components: { YamlEditor },
  data() {
    return {};
  },
};
</script>
<style>
.page {
  width: 100vw;
  height: 100vh;
  margin: 0;
}

.editor-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.CodeMirror-lint-tooltip {
  background-color: #eee;
  padding: 10px;
}
</style>
