import { parse as losslessParse, stringify } from 'lossless-json';

function isTimestamp(value) {
  // check used in svelte-jsoneditor lib
  // will break if not handled here
  const YEAR_2000 = 946684800000;
  const num = Number(value);
  return (
    num > YEAR_2000
    && Number.isFinite(num)
    && Math.floor(num) === num
    && !Number.isNaN(new Date(num).valueOf())
  );
}
function isInteger(value) {
  const INTEGER_REGEX = /^-?\d+$/;
  return INTEGER_REGEX.test(value);
}

function numberParser(value) {
  if (isInteger(value)) {
    return isTimestamp(value) ? Number.parseInt(value, 10) : window.BigInt(value);
  }
  return parseFloat(value);
}

export function isEmpty(value) {
  return !(Object.keys(value).length || value.length);
}

export function parse(val) {
  // custom parser for bigint compatibility
  return losslessParse(val, null, numberParser);
}

export { stringify };
